exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-atk-add-tsx": () => import("./../../../src/pages/atk/add.tsx" /* webpackChunkName: "component---src-pages-atk-add-tsx" */),
  "component---src-pages-atk-index-tsx": () => import("./../../../src/pages/atk/index.tsx" /* webpackChunkName: "component---src-pages-atk-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-vaccine-tsx": () => import("./../../../src/pages/vaccine.tsx" /* webpackChunkName: "component---src-pages-vaccine-tsx" */)
}

