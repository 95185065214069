import React from "react"
import Logo from "../images/logo-go.png"

const SplashLoading = () => {
  return (
    <div className="full-page-loader">
      <img width="200" src={Logo} alt="GoFight KMITL" />
    </div>
  )
}

export default SplashLoading
