import nhost from "nhost-js-sdk"

const BASE_URL = process.env.GATSBY_NHOST_URL || ""

const config = {
  base_url: BASE_URL,
}

nhost.initializeApp(config)

const auth = nhost.auth()
const storage = nhost.storage()

export { auth, storage }
