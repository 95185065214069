/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/css/index.css"
import React, { useEffect, useState } from "react"
import { auth } from "./src/libs/nhost"
import SplashLoading from "./src/components/SplashLoading"
import { NhostAuthProvider, useAuth } from "@nhost/react-auth"
import { Toaster } from "react-hot-toast"

const SessionCheck = ({ children }) => {
  const { signedIn } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading && signedIn === null) {
    return <SplashLoading />
  }

  return <React.Fragment>{children}</React.Fragment>
}

export const wrapRootElement = ({ element }) => {
  return (
    <SessionCheck>
      <NhostAuthProvider auth={auth}>{element}</NhostAuthProvider>
      <Toaster />
    </SessionCheck>
  )
}
