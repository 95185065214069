/**
 * https://www.gatsbyjs.com/plugins/gatsby-plugin-apollo/
 */

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  ApolloLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { getMainDefinition } from "@apollo/client/utilities"
import { SentryLink } from "apollo-link-sentry"
import Auth from "nhost-js-sdk/dist/Auth"
import { auth } from "../libs/nhost"

function sanitize(obj: object) {
  return Object.entries(obj)
    .filter(([key, value]) => value)
    .reduce((prev, curr) => ({ ...prev, [curr[0]]: curr[1] }), {})
}

export const createApolloClient = ({
  auth,
  headers,
  publicRole,
  defaultRole,
}: {
  auth?: Auth
  headers?: Record<string, string | undefined>
  publicRole?: string
  defaultRole?: string
}) => {
  const getheaders = (auth?: Auth) => {
    // add headers
    const resHeaders = {
      ...headers,
    }

    // add auth headers if signed in
    // or add 'public' role if not signed in
    if (auth) {
      if (auth.isAuthenticated()) {
        resHeaders.authorization = `Bearer ${auth.getJWTToken()}`
        if (defaultRole) {
          resHeaders["x-hasura-role"] = defaultRole
        }
      } else {
        resHeaders.role = publicRole
      }
    }

    return resHeaders
  }

  const uri = process.env.GATSBY_GRAPHQL_URL + "/v1/graphql"

  const httpLink = new HttpLink({
    uri,
    credentials: "include",
    headers: headers,
  })

  const authLink = setContext((a, { headers }) => {
    const finalizeHeaders = {
      ...getheaders(auth),
      ...headers,
    }
    return {
      headers: {
        ...sanitize(finalizeHeaders),
      },
    }
  })

  const link = split(({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === "OperationDefinition"
  }, authLink.concat(httpLink))

  const client = new ApolloClient({
    link: ApolloLink.from([
      new SentryLink({
        uri,
      }),
      link,
    ]),
    cache: new InMemoryCache({
      typePolicies: {},
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  })

  return {
    client,
  }
}

const { client } = createApolloClient({
  auth: auth,
  defaultRole: "user",
  publicRole: "anonymous",
})

export default client
